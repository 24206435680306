import { getSessionApi } from '@/api'
import { convertToFormData, isUuid } from '@utils/helpers'

const o = {
  keyName: '_sep'
}

export default {
  data () {
    return {
      service: {
        /**
         * @property {null|string} service.session.event - текущий event. Берётся из url (router/routes/main.js)
         * @property {null|string} service.session.session - текущий uuid сессии
         * @property {Boolean} service.session.recreateSession - если true сохраняем сессию в sessionStorage
         * @property {Boolean} service.session.showUUID - нужен ли редирект на uuid сессии
         */
        session: {
          event: null,
          session: null,
          recreateSession: false,
          showUUID: false
        }
      }
    }
  },

  watch: {
    '$route.path' () {
      this.startLoading()
      this.setEvent()
    }
  },

  mounted () {
    this.setEvent()
  },

  methods: {
    getSession () {
      if (isUuid(this.service.session.event)) {
        return {
          session: this.service.session.event
        }
      }

      const storageKeyName = `${o.keyName}.${this.service.session.event}`

      if (sessionStorage[storageKeyName]) {
        return JSON.parse(sessionStorage[storageKeyName])
      }

      if (localStorage[storageKeyName]) {
        return JSON.parse(localStorage[storageKeyName])
      }

      return null
    },

    /**
     * Попытка предотвратить ошибку, если по каким-то причинам
     * UUID сотрётся из localstorage
     * @return {string|null}
     */
    getSessionUUID () {
      const session = this.getSession()

      // в LS или SS есть сохранённая сессия
      if (session && session.session) {
        return session.session
      }

      // сессия не сохранена нигде
      if (!this.service.session.session) return null

      // сессия не сохранена только во внутренней памяти приложения
      this.setSessionToStorage(this.service.session)

      return this.service.session.session
    },

    setEvent () {
      // проверка авторизации lk_respondent
      if (this.$route.params.pathMatch.includes('lk_respondent')) {
        this.global.lk.fetchAuth()
      }

      this.service.event = this.service.session.event = this.$route.params.pathMatch
      this.checkSession()
    },

    setSessionToStorage (session) {
      if (isUuid(session.event)) return

      const storageType = session.recreateSession
        ? sessionStorage
        : localStorage

      storageType[`${o.keyName}.${this.service.session.event}`] = JSON.stringify(session)
    },

    setSession () {
      const sendData = convertToFormData({
        event: this.service.session.event
      })

      this.startLoading()

      getSessionApi(sendData)
        .then(response => {
          /**
             * @documentation https://ecopsy.atlassian.net/wiki/spaces/LKS/pages/2113306655#%D0%9E%D1%82%D0%BA%D1%80%D1%8B%D1%82%D0%B8%D0%B5-%D0%B0%D0%BD%D0%BE%D0%BD%D0%B8%D0%BC%D0%BD%D0%BE%D0%B3%D0%BE-%D0%B0%D0%BD%D0%BA%D0%B5%D1%82%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F
             *
             * response.data.recreateSession === true - сохраняем значение event=uuid в sessionStorage.
             * response.data.recreateSession !== true - сохраняем значение event=uuid в localStorage (поведение по умолчанию).
             *
             * response.data.showUUID === true - при переходе по alias мы редиректим пользователя на uuid сессии.
             * response.data.showUUID !== true - при переходе по alias мы оставляем пользователя на текущей странице с alias (поведение по умолчанию).
             */

          const { data } = response

          Object.assign(this.service.session, data)
          this.setSessionToStorage(data)

          if (this.service.session.showUUID) {
            this.redirectToUUID(this.service.session)
            return
          }

          this.getContent()
        },
        () => {
          this.$router.push('/')
          this.stopLoading()
        })
    },

    redirectToUUID ({ session }) {
      this.$router.replace({
        path: `/${session}`,
        query: this.$route.query
      })
    },

    checkSession () {
      if (isUuid(this.service.session.event)) {
        this.getContent()
        return
      }

      const session = this.getSession()

      if (session) {
        Object.assign(this.service.session, session)

        if (this.service.session.showUUID) {
          this.redirectToUUID(session)
          return
        }

        this.getContent()
      } else {
        this.setSession()
      }
    },

    clearSession () {
      localStorage.removeItem(o.keyName)
      localStorage.removeItem(o.keyName)
    }
  }
}
